<template>
  <article class="container-fluid">
    <div class="d-flex flex-column">
      <h1 class="main-title mb-4">{{ $t("overview.title") }}</h1>
      <wx-time-frame-date-picker
        ref="timeFrameDatePicker"
        v-if="activeFactory && !isFullScreen"
        :max-past-months="maxPastMonths"
        is-overview
      />
      <div class="mt-4">
        <wx-btn-standard @click="openMenu" class="font-weight-regular text-body-1" large>
          <v-icon class="mr-3">$lineChartViewIcon</v-icon>
          {{ activeKpiName }}
        </wx-btn-standard>
      </div>
    </div>
    <p-u-list v-if="isOverviewLoaded" :is-full-screen="isFullScreen" />
    <p-u-list-loader v-else />
    <tile-selector :is-overview="true" />
  </article>
</template>

<script>
import PUList from "@/components/overview/PUList";
import { mapGetters, mapActions } from "vuex";
import moment from "moment-timezone";
import WxTimeFrameDatePicker from "@/components/ui/timeframedatepicker/WxTimeFrameDatePicker.vue";
import PUListLoader from "@/components/overview/PUListLoader.vue";
import TileSelector from "@/components/dashboard/tileselectormenu/TileSelector.vue";
import WxBtnStandard from "@/components/ui/WxBtnStandard";

export default {
  name: "OverviewView",
  components: {
    PUListLoader,
    WxTimeFrameDatePicker,
    PUList,
    TileSelector,
    WxBtnStandard,
  },
  data() {
    return {
      fetchOverviewInterval: null,
      fetchDataSourceAlertsInterval: null,
      fetchCompletionKpisInterval: null,
    };
  },
  computed: {
    ...mapGetters("user", ["language", "isWorximityAdmin", "worximityAdminAllowedCoverageInMonths"]),
    ...mapGetters("overview", ["activeKpi", "activeProductionUnitIdForDetails", "isOverviewLoaded", "isFullScreen"]),
    ...mapGetters("navigation", ["activeFactory", "isLiveData", "isTimeRangeReady"]),
    maxPastMonths() {
      if (this.isWorximityAdmin) {
        return this.worximityAdminAllowedCoverageInMonths;
      }
      return 1;
    },
    language() {
      return this.$i18n.locale;
    },
    productionDayMinutesFromMidnight() {
      if (this.activeFactory && this.activeFactory.productionDayMinutesFromMidnight) {
        return this.activeFactory.productionDayMinutesFromMidnight;
      } else {
        return 0;
      }
    },
    isCalendarDayBusinessDay() {
      if (this.activeFactory && this.activeFactory.isCalendarDayBusinessDay) {
        return this.activeFactory.isCalendarDayBusinessDay;
      } else {
        return false;
      }
    },
    timezone() {
      if (this.activeFactory && this.activeFactory.timezone) {
        return this.activeFactory.timezone;
      } else {
        const zone = moment.tz.guess(true);
        return zone === null || zone === undefined ? "America/Montreal" : zone;
      }
    },
    activeKpiName() {
      return this.getKpiNameByTileKey(this.activeKpi);
    },
  },
  watch: {
    isTimeRangeReady() {
      this.startFetch();
    },
  },
  methods: {
    ...mapActions("overview", ["fetchOverview", "fetchProductionUnitOverview", "fetchOverviewCompletionKpis"]),
    ...mapActions("navigation", ["fetchFactoryDataSourceAlerts"]),
    ...mapActions("tiles", ["setIsMenuOpen", "setSelectedTileIndex"]),
    openMenu() {
      this.setSelectedTileIndex(this.activeKpi);
      this.setIsMenuOpen(true);
    },
    startFetchOverview() {
      if (!this.activeFactory) return;
      clearInterval(this.fetchOverviewInterval);
      if (this.activeFactory) {
        this.fetchOverview().then(() => {
          if (this.activeKpi === "production" || this.activeKpi === "time-to-completion") {
            this.fetchOverviewCompletionKpis();
          }
        });
        this.fetchOverviewInterval = setInterval(() => {
          this.fetchOverview().then(() => {
            if (this.activeKpi === "production" || this.activeKpi === "time-to-completion") {
              this.fetchOverviewCompletionKpis();
            }
          });
          if (this.activeProductionUnitIdForDetails) {
            this.fetchProductionUnitOverview(this.activeProductionUnitIdForDetails);
          }
        }, this.getRefreshDelay());
      }
    },
    getRefreshDelay() {
      if (this.isLiveData) {
        return 30000; // 30 seconds
      } else {
        return 300000; // 5 minutes
      }
    },
    startFetchFactoryDataSourceAlerts() {
      clearInterval(this.fetchDataSourceAlertsInterval);
      this.fetchFactoryDataSourceAlerts();
      this.fetchDataSourceAlertsInterval = setInterval(() => {
        this.fetchFactoryDataSourceAlerts();
      }, 60000);
    },
    startFetch() {
      this.startFetchFactoryDataSourceAlerts();
      this.startFetchOverview();
    },
    getKpiNameByTileKey(tileKey) {
      const kpiNameByTileKey = {
        availability: this.$t("overview.metrics.availability"),
        "total-uptime": this.$t("overview.metrics.uptime"),
        "total-downtime": this.$t("overview.metrics.plannedUnplannedDowntime"),
        performance: this.$t("overview.metrics.performance"),
        "product-throughput": this.$t("overview.metrics.ratePerHour"),
        "product-throughput-per-minute": this.$t("overview.metrics.ratePerMinute"),
        "product-speed-5m": this.$t("overview.metrics.speed5Minute"),
        "product-speed-5m-per-minute": this.$t("overview.metrics.speed5MinutePerMinute"),
        quality: this.$t("overview.metrics.quality"),
        "reject-quantity": this.$t("overview.metrics.netRejectQuantity"),
        oee: this.$t("overview.metrics.oee"),
        ooe: this.$t("overview.metrics.ooe"),
        "time-to-completion": this.$t("overview.metrics.timeToCompletion"),
        production: this.$t("overview.metrics.completionPercentage"),
        "current-product-quantity": this.$t("overview.metrics.currentProductQuantity"),
        "all-products-quantity": this.$t("overview.metrics.allProductQuantity"),
        "total-weight": this.$t("overview.metrics.totalWeight"),
        "total-volume": this.$t("overview.metrics.totalVolume"),
        "total-length": this.$t("overview.metrics.totalLength"),
        "product-giveaway-percent": this.$t("overview.metrics.giveawayPercentage"),
        "product-giveaway": this.$t("overview.metrics.giveaway"),
        "product-average-giveaway": this.$t("overview.metrics.averageWeightLengthVolume"),
      };

      return kpiNameByTileKey[tileKey];
    },
  },
  beforeDestroy() {
    clearInterval(this.fetchOverviewInterval);
    clearInterval(this.fetchDataSourceAlertsInterval);
  },
};
</script>

<style lang="scss" scoped>
.loading {
  height: 100%;
}
.dropdown-nav,
.filter-style {
  min-width: 14rem;
}
.dropdown-nav {
  flex-grow: 0;
  max-width: 20rem;

  @media ($wx-xl-min) {
    max-width: 22rem;
  }

  .time-selector-option {
    font-weight: 700;
  }
  .time-selector-date {
    color: var(--color-text-subtle-theme);
  }
}
.filter-style {
  max-width: 20rem;

  @media ($wx-sm-min) {
    max-width: 15rem;
  }
}
</style>
